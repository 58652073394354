@import '../../styles/config';

.top-nav {
  background-color: $green;
  height: $top-nav-height;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $z_top-nav;

  @media (max-width: #{$mobile-width}) {
    display: none;
  }

  @media print {
    display: none;
  }
}

.logo-link {
  display: inline-block;
  height: $top-nav-height;

  &:focus {
    @include focus-outline;
  }
}

.logo {
  max-height: 100%;
  max-width: 100%;
  padding: .7rem 1rem;
}

@import '../../styles/config';

.kebab-menu {
  :global(.dropdown-item) {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-family: 'OpenSans Regular';
    font-size: rem(14px);
    padding: .5rem .5rem .5rem 0;
  }

  :global(.svg-inline--fa) {
    align-items: center;
    display: inline-flex;
    font-size: 1rem;
    justify-content: center;
    margin: 0;
    width: 3rem;
  }
}

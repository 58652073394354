.checkbox {
  pointer-events: none;
}

.item {
  align-items: center;
  display: flex;
}

.content {
  flex-grow: 1;
  min-width: 0;
}

@import '../../styles/config';

.buttons-bottom {
  margin-top: auto;
}

@media (max-width: #{$max-responsive-width + 225px}) {
  .drawer-moved-up {
    bottom: 60px;
  }
}

.main-report {
  background-color: $white;
  border-radius: 25px;
  padding: 1rem;

  :global {
    .btn-link {
      color: $green;
      text-decoration: none;

      &:not(:disabled):not(.disabled) {
        &:hover,
        &:focus {
          color: $black;
          text-decoration: underline;
        }

        &:focus {
          outline: revert !important;
        }
      }
    }

    @media print {
      a,
      .btn-link {
        color: $black;
        text-decoration: none;
      }
    }
  }

  @media print {
    background-color: transparent;
    border-radius: 0;
  }
}

.section {
  border-bottom-style: groove;
  margin-bottom: 1rem;
  width: 100%;
}

.section-title {
  text-align: center;
}

.subsection-title {
  font-family: 'OpenSans Bold';
}

.subsection-children {
  margin-bottom: 1rem;
  white-space: break-spaces;

  :global(.btn-link) {
    min-height: unset;
    padding: 0;
  }
}

.section-table {
  border-bottom-style: none;
  margin: 0 1rem 1rem;

  .section-title {
    font-size: 1.1rem;
  }

  .section-children {
    margin: 0;
  }

  .subsection {
    padding: 0;
  }

  .subsection-title {
    background-color: $report-table-header;
    color-adjust: exact;
    font-family: 'OpenSans Regular';
    padding-left: 1rem;
  }

  .subsection-children {
    padding-left: 1rem;
  }
}

@media print {
  .edit-button {
    visibility: hidden;
  }
}

.edit-button {
  position: absolute;
  right: 45px;
  top: calc((55px - 44px) / 2);
}

.view-media-btn {
  padding: 0;

  :global(.svg-inline--fa) {
    margin-left: .5rem;
  }
}

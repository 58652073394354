@import '../../styles/config';

.name {
  vertical-align: text-bottom;
}

.owner {
  display: block;
  font-size: rem(14px);
}

.client-link {
  cursor: pointer;
}

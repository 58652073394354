@import '../../styles/config';

.search-toggle {
  align-items: center;
  cursor: pointer;
  display: flex;
  position: relative;

  &:global(.is-invalid) {
    background-position: right 1.75rem center;
  }
}

.toggle-text {
  flex-grow: 1;
  min-width: 0;
}

.clear-btn {
  color: $dark-text;

  &:hover {
    color: $black;
  }
}

.search-input {
  background: transparent;
  border: none;
  bottom: 0;
  left: 0;
  padding-left: 2.25rem;
  position: absolute;
  top: 0;
  width: calc(100% - 2.25rem);

  &::placeholder {
    color: $disabled-text;
  }

  &:focus {
    outline: none;
  }
}

.spinner {
  border-width: .2rem;
  height: 1.5rem;
  width: 1.5rem;
}

.display-text {
  margin-left: .5rem;
}

.placeholder {
  color: $disabled-text;
  user-select: none;
}

.menu {
  max-height: 200px;
  overflow-y: auto;
  width: 100%;

  &:focus {
    outline: none;
  }
}

@import '../../styles/config';

/* stylelint-disable property-case, property-no-unknown */
:export {
  darkText: $dark-text;
  green: $green;
}
/* stylelint-enabl property-case, property-no-unknown */

.progress {
  height: $min-btn-size;
  padding: .25rem;
  width: $min-btn-size;
}

.input {
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;

  &:global(.is-invalid) {
    .icon {
      right: 2.25rem;
    }
  }
}

.input-label {
  margin: 0;
  width: 210px;
}

.input-text {
  flex-grow: 1;
}

.density {
  margin-bottom: .5rem;

  :global(.invalid-feedback) {
    text-align: right;
  }
}

.icon {
  margin-left: .25rem;
  pointer-events: none;
  position: absolute;
  right: .75rem;
  top: .75rem;
}

@font-face {
  font-family: 'OpenSans Regular';
  font-size: .875rem;
  src: url('/fonts/OpenSans-Regular.ttf');
}

@font-face {
  font-family: 'OpenSans Bold';
  font-size: .875rem;
  src: url('/fonts/OpenSans-SemiBold.ttf');
}

@font-face {
  font-family: 'OpenSans Italic';
  font-size: .875rem;
  src: url('/fonts/OpenSans-Italic.ttf');
}

@for $index from 1 through 6 {
  h#{$index} {
    font-family: 'OpenSans Bold';
  }
}

h1 {
  font-size: 1.5rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 1rem;
}

h5 {
  font-size: .75rem;
}

p {
  font-size: .875rem;
}

i {
  font-family: 'OpenSans Italic';
}

@import '../../styles/config';

.upload--error {
  color: $red;

  &:hover {
    color: $red;
  }

  :global(.btn) {
    color: $red;
  }
}

.icon {
  align-items: center;
  display: flex;
  height: $min-btn-size;
  justify-content: center;
  min-width: $min-btn-size;
}

@import '../../styles/config';

/* stylelint-disable property-case, property-no-unknown */
:export {
  darkYellow: $dark-yellow;
}
/* stylelint-enabl property-case, property-no-unknown */

.invite {
  :global(.btn) {
    align-items: center;
    display: inline-flex;
    justify-content: center;
  }
}

.italics {
  color: $disabled-text;
  font-family: 'OpenSans Italic';
}

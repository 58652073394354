@mixin focus-outline {
  // Alternative to outline to support round buttons
  box-shadow: 0 0 0 2px adjust-color($green, $lightness: -10%) inset;
  outline: none;
}

@mixin btn($type, $bgcolor, $bgcolor-hover, $btn-color, $btn-border:transparent) {
  background-color: $bgcolor;
  border-color: $btn-border;
  color: $btn-color;

  &:not(:disabled):not(.disabled) {
    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      background-color: $bgcolor-hover;
      border-color: $btn-border;
      color: $btn-color;
    }

    &:active,
    &:active:focus {
      background-color: adjust-color($bgcolor-hover, $lightness: -5%);
    }

    &:active,
    &:focus,
    &:active:focus {
      border-color: transparent;
    }
  }

  .show > &.dropdown-toggle {
    @extend .btn-#{$type}, :hover;

    &:focus, &:active {
      box-shadow: none;
    }
  }
}

@mixin round-btn($radius: $round-btn-radius) {
  align-items: center;
  border-radius: $radius;
  box-shadow: 1px 1px 5px 1px rgba(0,0,0,.3);
  display: inline-flex;
  height: $radius * 2;
}

@mixin circle-btn($radius: $round-btn-radius) {
  @include round-btn($radius);
  justify-content: center;
  width: $radius * 2;

  &.btn-block {
    display: flex;
  }
}

@mixin checkbox-icon($color) {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23#{str-slice(inspect($color), 2)}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

@mixin radio-icon($color) {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' version='1.1'%3e%3ccircle r='6' cx='8' cy='8' fill='%23#{str-slice(inspect($color), 2)}'/%3e%3c/svg%3e");
}

@mixin select-bg($color) {
  background: #fff url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='1 4 16 16'%3E%3Cpath fill='%23#{str-slice(inspect($color), 2)}' d='M6 15L0 9h12z'%3E%3C/path%3E%3C/svg%3E") no-repeat right .5rem center/12px 13px;
}

@mixin card {
  align-items: center;
  background-color: $white;
  border: 1px solid $disabled-text;
  border-radius: $default-border-radius;
  color: $dark-text;
  display: flex;
  justify-content: center;
  padding: .6rem;
  text-align: center;
  text-decoration: none;

  &:focus,
  &:active:focus {
    @include focus-outline;
  }

  &:active {
    background-color: $white-hover;
  }

  &:hover {
    color: $black;
  }

  &:not(:focus):hover {
    box-shadow: 0 1px 6px 0 rgba(0,0,0,.3);
    cursor: pointer;
  }

  &:global(.disabled) {
    background-color: $white-hover;
    color: $disabled-text;
    pointer-events: none;

    &:hover {
      background-color: $white-hover;
      box-shadow: none;
    }
  }
}

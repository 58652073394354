.measurement-input {
  display: flex;

  & > * {
    flex-grow: 1;

    &:first-child {
      margin-right: 1rem;
    }
  }
}

.form-control {
  border-color: $grey;
  border-radius: 4px;
  color: $dark-text;

  &:focus {
    box-shadow: none;
  }

  &:hover:not(:disabled):not(.disabled),
  &:focus:not(:disabled):not(.disabled),
  &:focus-within:not(:disabled):not(.disabled) {
    box-shadow: none;
    color: $black;

    &:not(.is-invalid) {
      border-color: $input-hover;
    }

    &.dropdown-toggle::before {
      background-color: $input-hover;
      transition: background-color $anim-dur ease-in-out;
    }

    &.placeholder, &::placeholder {
      color: $disabled-text;
    }
  }

  &.placeholder, &::placeholder {
    cursor: default;
    user-select: none;
  }

  &:disabled, &.disabled {
    background-color: $white-hover;
    border-color: $disabled-text;
    color: $disabled-text;
    cursor: default;
  }

  &.placeholder, &::placeholder {
    color: $disabled-text;
  }

  &.dropdown-toggle {
    padding-right: 1.75rem;
    text-align: left;
  }

  &.dropdown-toggle::after {
    position: absolute;
    right: .8rem;
    top: 1rem;
  }
}

/************ Custom Overrides ************/

.custom-control-label::before {
  border-color: $green;
}

.custom-control-input:focus,
.custom-control-input:focus:not(:checked) {

  & ~ .custom-control-label::before {
    border-color: transparent;
    box-shadow: 0 0 0 2px adjust-color($green, $lightness: -10%);
  }
}

.custom-checkbox .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  background-color: adjust-color($green, $lightness: -10%);
  border-color: transparent;
}

.custom-checkbox .custom-control-input:disabled,
.custom-checkbox .custom-control-input:disabled:checked {
  & ~ .custom-control-label::before {
    background-color: $white-hover;
  }
}

.custom-checkbox .custom-control-input:not(:disabled):active ~ .custom-control-label::after {
  @include checkbox-icon($white);
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::after {
  @include checkbox-icon($disabled-text);
}

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: $white-hover;
  border-color: $disabled-text;
}

.custom-radio .custom-control-label::after {
  background: no-repeat;
}

.custom-radio .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  background-color: transparent;
  border-color: transparent;
  box-shadow: 0 0 0 2px adjust-color($green, $lightness: -10%);
}

.custom-radio .custom-control-input ~ .custom-control-label::before {
  background-color: transparent;
}

.custom-radio .custom-control-input:disabled,
.custom-radio .custom-control-input:disabled:checked {
  & ~ .custom-control-label::before {
    background-color: transparent;
  }
}

.custom-radio .custom-control-input {
  & ~ .custom-control-label::after {
    @include radio-icon($white);
  }

  &:active, &:checked {
    & ~ .custom-control-label::after {
      @include radio-icon($green);
    }
  }
}

.custom-radio .custom-control-input:disabled {
  &:active, &:checked {
    & ~ .custom-control-label::after {
      @include radio-icon($disabled-text);
    }
  }
}

.custom-select {
  @include select-bg($dark-text);

  &:hover:not(:disabled):not(.disabled),
  &:focus:not(:disabled):not(.disabled) {
    @include select-bg($black);
  }
}

@import '../../styles/config';

.filter {
  display: flex;
  margin-bottom: .5rem;

  & > :first-child {
    flex-grow: 1;
    margin-right: .5rem;
    min-width: 0;
  }
}

.filter-btn {
  min-width: 3rem;
  position: relative;

  &[data-count]:after {
    background: $accent;
    border-radius: 50%;
    bottom: 8%;
    color: $black;
    content: attr(data-count);
    font-size: 60%;
    min-width: .8rem;
    position: absolute;
    right: 10%;
    text-align: center;
  }
}

.icon {
  margin: 0 !important;
}

$black: #00170d;
$content-bg: #f0f1f2;
$dark-text: #333;
$disabled-text: #999;
$zero-text: #9297a0;
$green: #006338;
$input-grey: #2c363f;
$input-hover: #009c28;
$light-green: #95be79;
$grey: #2c363f;
$nav-heading-text: #00171f;
$nav-hover: #ebebeb;
$trans-white: rgba(255, 255, 255, .8);
$white: #fff;
$report-table-header: #cdcdcd;
$left-nav-bg: #f4f4f5;
$white-hover: #e5e7eb;
$white-active: #e2e2e2;
$table-alt-row: #f3f6f7;
$table-row-hover: #e8e8e8;
$yellow: #f7d417;
$dark-yellow: #d2b850;
$accent: #9ad2cb;
$red: #c22724;

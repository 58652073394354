@import '../../styles/config';

.header {
  background-color: $white;
  margin: 0;
  padding: 1rem 0;
  text-align: center;
}

.dates-container {
  background-color: $white;
  display: flex;
  gap: .75rem;
  justify-content: center;
  padding: 0 1rem 1rem;
}

.date-box {
  background-color: $white;
  border: 1px solid $disabled-text;
  border-radius: $default-border-radius;
  flex-basis: 0;
  flex-grow: 1;
  padding: 10px 0;
  text-align: center;
}

.value {
  font-family: 'OpenSans Bold';
  margin-top: 5px;
}

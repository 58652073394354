.modal {
  bottom: 0;
  height: unset;
}

.modal-dialog {
  margin: 6rem auto;
  max-width: $max-responsive-width;
  width: calc(100% - 2rem);
}

.modal-header {
  border: none;
  justify-content: center;
}

.modal-title {
  font-family: 'OpenSans Bold';
  font-size: rem(20px);
}

.modal-body {
  padding: 0 1rem;
}

.modal-footer {
  border: none;

  .btn {
    align-items: center;
    display: flex;
    flex-grow: 1;
    justify-content: center;
  }
}

@import '../../styles/config';

.list-card, a.list-card {
  @include card;
  margin: .5rem 0;
  width: 100%;

  &:hover, &:focus, &:active {
    .arrow {
      color: $dark-text;
    }
  }

  :global(.btn-link) {
    align-items: center;
    display: flex;
  }
}

.content {
  align-items: center;
  flex-grow: 1;
  min-width: 0;
  text-align: left;
}

.arrow {
  color: $disabled-text;

  :global(.svg-inline--fa) {
    margin: 0;
  }
}

.detail-subtext {
  flex-basis: 0;
  flex-grow: 1;
  padding: 0 .25rem;
  text-align: right;
}

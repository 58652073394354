@import '../../styles/config';

:global {
  :local(.custom-input) {
    align-items: center;
    display: flex;

    &.is-invalid ~ .react-datepicker__close-icon {
      right: 3.5rem !important;
    }
  }
}

.placeholder {
  color: $disabled-text;
  cursor: default;
  user-select: none;
}

.input-text {
  flex-grow: 1;
}

.icon {
  margin-left: .25rem;
  pointer-events: none;
}

.icon--disabled {
  color: $disabled-text;
}

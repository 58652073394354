@import '../../styles/config';

.drawer {
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 130ms ease-in;
  z-index: calc(#{$z_drawer} + 1);

  &:focus {
    outline: none;
  }

  @media (max-width: #{$mobile-width}) {
    position: fixed;

    &.drawer--open {
      background-color: $trans-white;
      bottom: 0;
      pointer-events: all;
    }

    .default-item {
      display: flex;
    }

    .default-item .icon {
      display: none;
    }

    .drawer-item .label {
      opacity: 0;
      transform: translateX(1rem);
      transition: opacity $anim-dur ease-in, transform $anim-dur ease-in;
      will-change: transform;
    }
  }
}

.toggle {
  display: flex;
  pointer-events: all;
  position: absolute;
  right: 1rem;
  top: 1.5rem;
  transition: all $anim-dur ease-in;
  will-change: transform;

  &:focus {
    outline: none;
  }

  @media (max-width: #{$mobile-width}) {
    bottom: 1rem;
    top: unset;
  }
}

.menu {
  background-color: $white;
  border-radius: 4px;
  box-shadow: 1px 1px 5px 1px rgba(0,0,0,.3);
  display: inline-block;
  min-width: 200px;
  opacity: 0;
  padding: .5rem 0;
  pointer-events: none;
  position: absolute;
  right: 1rem;
  top: 1.5rem;
  transform: translateY(-25%);
  transition: all $anim-dur ease-in;
  will-change: transform;

  @media (max-width: #{$mobile-width}) {
    background-color: transparent;
    bottom: calc((#{$round-btn-radius} * 2) + 1.25rem);
    box-shadow: none;
    padding: 0;
    top: unset;
    transform: translateY(1rem);
  }
}

.drawer:not(.drawer--open) {
  .toggle {
    transition-delay: $anim-dur;
  }

  .menu {
    @media (max-width: #{$mobile-width}) {
      transition-delay: $anim-dur;
    }
  }
}

.drawer--open {
  .toggle {
    opacity: 0;
    transform: translateY(-25%);

    @media (max-width: #{$mobile-width}) {
      cursor: pointer;
      justify-content: flex-end;
      min-width: 200px;
      opacity: 1;
      transform: none;

      :global(.btn) {
        background-color: $white;
      }

      &:not(:disabled):not(.disabled) {
        &:active,
        &:active:focus-within {
          outline: none;

          :global(.btn) {
            background-color: $green;
            color: $white;
          }
        }

        @media (pointer:fine) {
          &:hover,
          &:active,
          &:active:focus-within,
          &:focus-within {
            outline: none;

            :global(.btn) {
              background-color: $green;
              color: $white;
            }
          }
        }
      }

      :global(.btn) {
        color: $green;
        pointer-events: none;

        &:hover {
          background-color: $white;
        }
      }
    }
  }

  .menu {
    opacity: 1;
    pointer-events: all;
    transform: none;
    transition-delay: $anim-dur;

    @media (max-width: #{$mobile-width}) {
      transition-delay: 0ms;
    }
  }

  .drawer-item {
    @media (max-width: #{$mobile-width}) {
      pointer-events: all;

      .label {
        opacity: 1;
        transform: none;
      }
    }
  }

  .drawer-item .label {
    @media (max-width: #{$mobile-width}) {
      transition-delay: $anim-dur;
    }
  }
}

.drawer-item {
  align-items: center;
  background-color: $white;
  cursor: pointer;
  display: flex;
  padding: .5rem .5rem .5rem 0;

  &:hover {
    background-color: $white-hover;
  }

  &:focus {
    background-color: $grey;
    color: $white;
    outline: none;
  }

  &:not(.default-item) .label {
    padding-right: calc(#{$round-btn-radius} - #{$sm-round-btn-radius} + .5rem);
  }

  @media (max-width: #{$mobile-width}) {
    background-color: transparent;
    color: $green;
    flex-direction: row-reverse;
    padding-right: calc(#{$round-btn-radius} - #{$sm-round-btn-radius});

    &:not(:disabled):not(.disabled) {
      background-color: transparent;

      &:hover,
      &:focus {
        background-color: transparent;
        color: $green;

        :global(.btn) {
          color: $green;
        }
      }

      &:active, &:active:focus {
        color: $green;
        outline: none;

        :global(.btn) {
          background-color: $green;
          color: $white;
        }
      }

      @media (pointer:fine) {
        &:hover,
        &:focus {
          color: $green;
          outline: none;

          :global(.btn) {
            background-color: $green;
            color: $white;
          }
        }
      }
    }
  }
}

.default-item {
  display: none;
  padding-right: .5rem;
  pointer-events: none;
}

.icon {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  width: 3rem;

  @media (max-width: #{$mobile-width}) {
    background-color: $white;
    color: $green;
    width: $sm-round-btn-radius * 2;
  }
}

.label {
  font-size: rem(14px);
  user-select: none;
}

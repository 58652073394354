@import '../../styles/config';

.left-nav {
  background: $left-nav-bg;
  display: flex;
  flex-direction: column;
  margin-top: $top-nav-height;
  pointer-events: none;
  width: 325px;

  :global(.btn-link) {
    display: block;
    margin: 0;
    width: 100%;
  }

  @media (max-width: #{$mobile-width}) {
    background: transparent;
    height: 100%;
    left: 0;
    margin-top: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: calc(#{$z_left-nav} + 1);
  }

  @media print {
    display: none;
  }
}

.header {
  border-bottom: 1px solid $grey;
  margin: 1rem;
  padding-bottom: 1rem;
}

.header-placeholder {
  @extend .header;
  border-color: $white-hover;
}

.nav-item, a.nav-item {
  border-radius: 0;
  color: $dark-text;
  padding: .5rem 1rem;
  position: relative;
  text-align: left;
  text-decoration: none;

  &:before {
    content: '';
    display: block;
    transition: width $anim-dur ease-in-out;
    width: 0;
  }

  &:hover, &:focus, &.focus {
    background-color: $nav-hover;
    text-decoration: none;
  }

  &:global(.active) {
    background-color: $white-active;
    box-shadow: none;

    &:before {
      background-color: $grey;
      bottom: -1px;
      content: '';
      display: block;
      left: -1px;
      position: absolute;
      top: -1px;
      width: 5px;
    }
  }

  &:focus,
  &:not(:disabled):not(.disabled):focus {
    background-color: $grey;
    box-shadow: none;
    color: $white;
  }

  &:not(:disabled):not(.disabled) {
    &:active:focus {
      background-color: $white-active;
      box-shadow: none;
      color: $dark-text;
    }
  }
}

// Referenced: https://medium.com/outsystems-experts/how-to-achieve-60-fps-animations-with-css3-db7b98610108
.menu {
  display: block;
  height: 100%;
  pointer-events: none;
  width: inherit;

  &:before {
    bottom: 0;
    box-shadow: inset -7px 0 9px -7px rgba(0,0,0,.3);
    content: '';
    display: block;
    max-width: $left-nav-width;
    position: absolute;
    top: 0;
    width: 90%;
    z-index: $z_left-nav;
  }

  &:focus {
    outline: none;
  }

  @media(max-width: $mobile-width) {
    box-shadow: none;
    height: 100%;
    left: 0;
    overflow: hidden;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: calc(#{$z_left-nav} + 1);

    &:before {
      display: none;
    }

    &:after {
      background: rgba(0,0,0,.4);
      content: '';
      display: block;
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      transition: opacity .3s cubic-bezier(0,0,.3,1);
      width: 100%;
      will-change: opacity;
    }
  }
}

.menu-inner {
  pointer-events: auto;

  &:focus {
    outline: none;
  }
}

@media(max-width: $mobile-width) {
  .menu:not(.menu--open):not(.menu--animatable) .menu-inner {
    visibility: hidden;
  }
  .menu-inner {
    background: $left-nav-bg;
    box-shadow: 0 2px 6px rgba(0, 0, 0, .5);
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: $left-nav-width;
    position: relative;
    transform: translateX(-103%);
    width: 90%;
    will-change: transform;
    z-index: calc(#{$z_left-nav} + 2);
  }

  .menu--open {
    pointer-events: auto;

    &:after {
      opacity: 1;
      pointer-events: auto;
    }

    .menu-inner {
      transform: none;
    }
  }

  .menu--animatable .menu-inner {
    transition: all 130ms ease-in;
  }

  .menu--open.menu--animatable .menu-inner {
    transition: all 330ms ease-out;
  }
}

// Referenced https://jonsuh.com/hamburgers/
.hamburger {
  border: 0;
  cursor: pointer;
  display: none;
  height: $mobile-header-height;
  pointer-events: auto;
  width: calc(#{$hamburger-width} + 1.5rem);

  &:hover {
    .hamburger-inner,
    .hamburger-inner:after,
    .hamburger-inner:before {
      background-color: $white-hover;
    }
  }

  &:focus {
    @include focus-outline;
  }

  @media(max-width: $mobile-width) {
    display: inline-block;
  }
}

.hamburger-box {
  display: inline-block;
  height: $hamburger-height;
  margin: calc((#{$mobile-header-height} - #{$hamburger-height}) / 2) .75rem;
  position: relative;
  width: $hamburger-width;
}

.hamburger-inner,
.hamburger-inner:after,
.hamburger-inner:before {
  background-color: $white;
  border-radius: 3px;
  height: 3px;
  position: absolute;
  transition-duration: $anim-dur;
  transition-property: transform;
  transition-timing-function: ease;
  width: $hamburger-width;
}
.hamburger-inner:before {
  top: 7px;
}

.hamburger-inner:after {
  bottom: -7px;
  top: 14px;
}

.hamburger-inner:after,
.hamburger-inner:before {
  content: '';
  display: block;
}

.search-menu[x-placement^=bottom] {
  right: 0;
}

.org-name {
  align-items: center;
  display: flex;
  height: $input-height;
}

@import '../../styles/config';

.page-header {
  align-items: center;
  background-color: $white;
  color: $dark-text;
  display: flex;
  height: $desktop-header-height;
  justify-content: center;
  margin: 0;
  outline: none;
  padding: 1rem;

  @media(max-width: $mobile-width) {
    background-color: $green;
    color: $white;
    height: $mobile-header-height;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: $z_top-nav;

    // Position on right if not covering hamburger
    &:not(.page-header--cover) .secondary-action {
      left: unset;
      right: 0;
    }
  }

  @media print {
    display: none;
  }
}

.page-header--cover {
  z-index: $z_left-nav + 1;
}

.loading-text {
  background-color: $white-hover;
  border-radius: .25rem;
  color: $white-hover;
  user-select: none;

  @media(max-width: $mobile-width) {
    display: none;
  }
}

.secondary-action {
  left: 0;
  padding: 0;
  position: absolute;
  top: calc((#{$desktop-header-height} - #{$min-btn-size}) / 2);

  @media(max-width: $mobile-width) {
    top: calc((#{$mobile-header-height} - #{$min-btn-size}) / 2);
  }
}

.secondary-action--right {
  left: unset;
  right: 0;
}

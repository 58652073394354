@import 'config';
@import 'bootstrap';
@import 'buttons';
@import 'dialogs';
@import 'inputs';
@import 'typography';

html, body {
  /* Removes higlight on tap on mobile devices. */
  -webkit-tap-highlight-color: transparent;
}

html, body, #root, [role='main'] {
  height: 100%;
}

body {
  font-family: 'OpenSans Regular';
  margin: 0;
  overflow-y: auto;
  /* Disables "double tap to zoom in" on mobile devices. */
  /* https://stackoverflow.com/questions/46167604/iphone-html-disable-double-tap-to-zoom */
  touch-action: manipulation;
}

hr {
  border-top: 1px solid $grey;
}

a .svg-inline--fa {
  margin-right: .3125rem;
}

.react-datepicker-wrapper {
  display: block !important;
}

.react-datepicker-time__input input {
  width: 95px !important;
}

.react-datepicker__portal {
  align-items: flex-start !important;
  padding: 1rem;
}

.react-datepicker__close-icon {
  right: 2rem !important;
}

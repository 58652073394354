.btn {
  border: 1px solid transparent;
  border-radius: $default-border-radius;
  font-family: 'OpenSans Bold';

  &:focus,
  &:not(:disabled):not(.disabled):active:focus {
    @include focus-outline;
  }

  &:not(.btn-link) {
    &:disabled, &.disabled {
      background-color: $white-hover;
      border: 1px solid transparent;
      color: $disabled-text;
      cursor: default;

      &:hover {
        background-color: $white-hover;
      }
    }
  }
}

/************ Colors ************/

.btn-primary {
  @include btn(
    'primary',
    $bgcolor: $green,
    $bgcolor-hover: adjust-color($green, $lightness: -3%),
    $btn-color: $white,
  );
}

.btn-secondary {
  @include btn(
    'secondary',
    $bgcolor: $white,
    $bgcolor-hover: $white-hover,
    $btn-color: $green,
    $btn-border: $green,
  );
}

.btn-white {
  @include btn(
    'white',
    $bgcolor: $white,
    $bgcolor-hover: $white-hover,
    $btn-color: $green,
  );
}

.btn-header-action {
  @include btn(
    'header-action',
    $bgcolor: transparent,
    $bgcolor-hover: transparent,
    $btn-color: $dark-text,
  );

  align-items: center;
  border-color: transparent;
  display: inline-flex;
  font-size: 1.5rem;
  height: $min-btn-size;
  justify-content: center;
  transition: color $anim-dur ease-in-out;
  width: $min-btn-size;

  &:hover:not(:disabled):not(.disabled),
  &:not(:disabled):not(.disabled):active {
    border-color: transparent;
    color: adjust-color($dark-text, $lightness: 10%);
  }

  @media(max-width: $mobile-width) {
    background-color: $green;
    color: $white;

    &:not(:disabled):not(.disabled) {
      &:hover,
      &:focus,
      &:active,
      &:active:focus {
        color: $white-hover;
      }
    }
  }
}

.btn-link {
  color: $dark-text;
  font-family: 'OpenSans Regular';
  min-height: $min-btn-size;
  min-width: $min-btn-size;
  text-decoration: underline;

  &:not(:disabled):not(.disabled) {
    &:hover {
      color: adjust-color($dark-text, $lightness: 10%);
    }

    &:focus {
      box-shadow: none;
      color: $green;
    }

    &:active:focus {
      box-shadow: none;
      color: adjust-color($green, $lightness: 10%);
    }
  }
}

/************  Sizes ************/

.btn-round {
  @include round-btn;

  .svg-inline--fa {
    margin-right: .5rem;
  }
}

.btn-circle {
  @include circle-btn;
}

.btn-sm-circle {
  @include circle-btn($radius: $sm-round-btn-radius);
}

/************ Misc ************/

.btn-upload {
  height: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 0;
  z-index: -1;

  &:not(:disabled):not(.disabled) {
    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      & + label {
        background-color: adjust-color($green, $lightness: -3%);
        // border-color: $btn-border;
        // color: $white;
      }
    }

    &:active,
    &:active:focus {
      & + label {
        background-color: adjust-color($green, $lightness: -10%);
      }
    }

    &:active,
    &:focus,
    &:active:focus {
      & + label {
        @include focus-outline;
        border-color: transparent;
      }
    }
  }
}

.btn-block {
  align-items: center;
  display: flex;
  justify-content: center;
}

.btn .spinner-border {
  border-width: .15rem;
}

.btn:not(.btn-link) .spinner-border {
  border-width: .15rem;
  margin-left: .4rem;
}

.dropdown-item:hover {
  background-color: $white-hover;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: $white-hover;
  color: $dark-text;
}

.dropdown-item.selected,
.dropdown-item:focus {
  background-color: $grey;
  color: $white;
  outline: none;
}

.dropdown-divider {
  border-top: 1px solid $zero-text;
  margin: .5rem 1rem;
}

@import 'config';

/* stylelint-disable property-case, property-no-unknown */
:export {
  mobileWidth: $mobile-width;
}
/* stylelint-enabl property-case, property-no-unknown */

.app {
  display: flex;
  height: 100%;
  position: relative;
  z-index: $z_app;
}

.page {
  background-color: $content-bg;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: $top-nav-height;
  min-width: 0;
  position: relative;

  @media (max-width: #{$mobile-width}) {
    margin-top: 0;
  }
}

.page-content {
  background-color: $content-bg;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  padding: 1rem;
  position: relative;

  @media(max-width: $mobile-width) {
    margin-top: $mobile-header-height;
  }
}

.page-content--drawer {
  padding: 2.5rem 1rem 1rem;

  @media(max-width: $mobile-width) {
    padding: 1rem;
  }
}

.responsive-container {
  align-self: center;
  max-width: $max-responsive-width;
  width: 100%;
}

.responsive-container--buttons-bottom {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.responsive-container--margin {
  margin-top: 3rem;

  @media(max-width: $mobile-width) {
    margin-top: 0;
  }
}

.page-desc {
  border-bottom: 1px solid $grey;
  padding-bottom: 1rem;
  text-align: center;
}

a.skip-main {
  height: 1px;
  left: -999px;
  overflow: hidden;
  position: absolute;
  top: auto;
  width: 1px;
  z-index: -999;
}

a.skip-main:focus, a.skip-main:active {
  border-radius: 15px;
  color: $white;
  font-size: 1em;
  height: unset;
  left: auto;
  overflow: auto;
  text-align: center;
  top: auto;
  width: unset;
  z-index: 999;
}

.input-placeholder {
  background: $white-hover;
  border-radius: $default-border-radius;
  height: $input-height;
}

.list-card-placeholder {
  background: $white-hover;
  border-radius: $default-border-radius;
  height: $input-height;
  margin-bottom: 1rem;
}

.link-placeholder {
  border-radius: $default-border-radius;
  margin: 1rem;
}

.tab-list {
  background-color: $white;
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: center;
}

.tab {
  color: $dark-text;
  flex-grow: 1;
  font-family: 'OpenSans Bold';
  margin: 0 .75rem;
  padding: .25rem .75rem;
  text-align: center;

  &:hover {
    text-decoration: none;
  }

  &:not(:global(.active)):hover {
    border-bottom: 4px solid adjust-color($green, $alpha: -.5);
  }
}

.tab:global(.active) {
  border-bottom: 4px solid $green;
}

@media print {
  .page {
    background-color: $white;
    margin-top: 0 !important;
  }

  .page-content {
    background-color: $white;
    overflow-y: visible;
    padding: 0;
  }
}

@import '../../styles/config';

.zone {
  margin-bottom: .5rem;
}

.zone__inner {
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.zone-label {
  margin: 0;
  width: 210px;
}

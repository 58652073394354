@import '../../styles/config';

.tile-board {
  display: inline-grid;
  gap: .75rem;
  grid-template-columns: repeat(5, auto);

  @media (max-width: 1300px) {
    grid-template-columns: repeat(4, auto);
  }

  @media (max-width: 1015px) {
    grid-template-columns: repeat(3, auto);
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(2, auto);
  }
}

.tile, a.tile {
  @include card;
  flex-flow: column wrap;
  height: $tile-size;
  width: $tile-size;
  word-break: break-word;
}

.tile--trans {
  background-color: transparent;
  border: none;
  cursor: default;

  &:focus,
  &:active,
  &:active:focus {
    background-color: transparent;
    box-shadow: none;
  }

  &:not(:focus):hover {
    box-shadow: none;
  }
}

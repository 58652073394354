@import '../../styles/config';

.steps {
  display: flex;
  min-height: 3rem;
}

.step {
  align-items: center;
  display: flex;
}

.step:not(:first-child) {
  flex-basis: 0;
  flex-grow: 1;
  justify-content: flex-end;

  .line {
    background-color: $grey;
    flex-grow: 1;
    height: 2px;
    margin-left: -(calc((($min-btn-size - $step-size) / 2)));
    margin-right: -(calc((($min-btn-size - $step-size) / 2)));
  }
}

.link {
  align-items: center;
  color: $white;
  display: flex;
  height: $min-btn-size;
  justify-content: center;
  width: $min-btn-size;

  &:hover {
    color: $white;
    text-decoration: none;
  }

  & > * {
    align-items: center;
    background-color: $grey;
    border-radius: 50%;
    display: flex;
    font-size: rem(14px);
    height: $step-size;
    justify-content: center;
    width: $step-size;
  }
}

.link--hidden {
  display: none;
}

.link:hover, .link--selected {
  & > * {
    background-color: $green;
  }
}

@media (min-width: #{$mobile-width}) {
  .drawer--steps {
    top: 40px;
  }
}

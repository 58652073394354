@import 'config';

.nav-heading {
  color: $nav-heading-text;
  font-family: 'OpenSans Bold';
  font-size: rem(14px);
  text-transform: uppercase;
}

.subtext {
  color: $disabled-text;
  font-size: rem(14px);
}

.loading-text {
  background-color: $white-hover;
  border-radius: .25rem;
  color: $white-hover;
  user-select: none;

  @media(max-width: $mobile-width) {
    background-color: $white;
    color: $white;
  }
}

.inline-link {
  font-size: .875rem;
  line-height: initial;
  padding: 0 .25rem;
  vertical-align: initial;
}

.ellipsize {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:not(td,th) {
    display: block;
  }
}

@import 'config';

.table {
  width: 100%;

  th, td {
    padding: .25rem 2rem;
  }

  :global(.btn-link) {
    color: $dark-text;

    &:not(:disabled):not(.disabled) {
      &:hover {
        color: adjust-color($dark-text, $lightness: 10%);
      }

      &:focus {
        box-shadow: none;
        color: $green;
      }

      &:active:focus {
        box-shadow: none;
        color: adjust-color($green, $lightness: 10%);
      }
    }
  }

  tbody tr {
    &:not(:last-child):hover {
      background-color: $table-row-hover !important;
    }

    &:nth-child(odd):not(:last-child) {
      background-color: $table-alt-row;
    }
  }

  @media (max-width: $mobile-width) {
    table-layout: fixed;

    th, td {
      padding: .4rem 1rem;
    }

    .actions {
      width: 140px;
    }
  }
}

.header {
  background-color: $grey;
  color: $white;
  font-family: 'OpenSans Bold';
}

.zero {
  font-size: rem(14px);
  padding: 2rem !important;
  text-align: center;
}

.spinner {
  border-width: .2rem;
  height: 1.5rem;
  width: 1.5rem;
}

.end {
  color: $zero-text;
  font-size: rem(14px);
  height: 5rem;
  text-align: center;
}

.actions {
  height: calc(#{$min-btn-size} + .5rem);
  text-align: center;
  width: 170px;

  :global(.svg-inline--fa) {
    height: 1rem;
    width: 1rem;
  }
}

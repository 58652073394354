@import '../../styles/config';

.search {
  margin: 1rem 0;
  position: relative;
}

.search-input {
  padding-right: $min-btn-size;
}

.clear-btn {
  height: $input-height;
  min-height: unset;
  position: absolute;
  right: 0;
  top: 0;
}

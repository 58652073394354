// Common
$mobile-width: 1000px;
$default-border-radius: 4px;
$max-responsive-width: 540px;
$min-btn-size: 44px;
$input-height: calc(1.5em + .75rem + 2px);

// Navigation
$top-nav-height: 60px;
$left-nav-width: 325px;
$tile-size: 155px;

// Hamburger
$hamburger-height: 18px;
$hamburger-width: 24px;

//Headers
$mobile-header-height: $min-btn-size;
$desktop-header-height: 55px;

// Buttons
$round-btn-radius: 30px;
$round-btn-padding: 1.25rem;
$sm-round-btn-radius: 20px;

// Report Steps
$step-size: 30px;

// Durations
$anim-dur: .15s;

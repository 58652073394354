@import '../../styles/config';

.carousel {
  color: $white;
  height: 100%;
  margin: 0;
  max-width: unset;
  min-width: 0;
  width: 100%;

  :global {
    .modal-content {
      background-color: transparent;
      border: none;
      height: 100%;
      min-width: 0;
      width: unset;
    }

    .swiper-wrapper {
      align-items: center;
    }

    .swiper-container {
      height: 100%;
      width: 100%;
    }
  }
}

.slide {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 1rem;
}

.image {
  max-height: 100%;
  max-width: 100%;
}

.controls {
  align-items: center;
  background-color: $grey;
  color: $white;
  display: flex;
  min-height: $top-nav-height;

  .title {
    flex-grow: 1;
    padding-left: 1rem;
  }

  :global {
    a,
    .btn-link {
      align-items: center;
      color: $white;
      display: flex;
      font-size: 1.25rem;
      padding: .25rem .75rem;

      &:not(:disabled):not(.disabled) {
        &:hover {
          color: adjust-color($white, $lightness: -30%);
        }

        &:focus {
          color: $yellow;
          outline: none;
        }

        &:active:focus {
          color: adjust-color($yellow, $lightness: 10%);
        }
      }
    }
  }

  @media(max-width: $mobile-width) {
    min-height: $mobile-header-height;
  }
}
